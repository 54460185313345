body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

::-webkit-scrollbar {
    background-color: #24272b;
    height: 8px;
    width: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 25px;
    background: #141416;
}

::-webkit-scrollbar-thumb {
    background: #2D3034;
    border-radius: 25px;
    height: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: #2D3034;
}

@font-face {
    font-family: "ArchivoBlack";
    src: local("ArchivoBlack"),
        url("./fonts/Archivo/Archivo-Black.ttf") format("truetype");
    font-weight: bolder;
}

@font-face {
    font-family: "ArchivoBold";
    src: local("ArchivoBold"),
        url("./fonts/Archivo/Archivo-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "ArchivoRegular";
    src: local("ArchivoRegular"),
        url("./fonts/Archivo/Archivo-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "PublicSansBlack";
    src: local("PublicSansBlack"),
        url("./fonts/PublicSans/PublicSans-Black.ttf") format("truetype");
    font-weight: bolder;
}

@font-face {
    font-family: "PublicSansBold";
    src: local("PublicSansBold"),
        url("./fonts/PublicSans/PublicSans-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "PublicSansRegular";
    src: local("PublicSansRegular"),
        url("./fonts/PublicSans/PublicSans-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "PublicSansThin";
    src: local("PublicSansThin"),
        url("./fonts/PublicSans/PublicSans-Thin.ttf") format("truetype");
    font-weight: lighter;
}